import { MsalContext } from "@azure/msal-react";
import axios from "axios";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/ed76e166-5473-4e31-8eec-2c23d80824a5",
    clientId: "214c6ff4-aee1-4e28-8aa1-33008b451640",
    // redirectUri: "https://delightful-forest-0878a1103.4.azurestaticapps.net/",
    redirectUri: "https://polusui.ce-service.co.uk/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read"],
};
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + "/auth.html",
};
export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);

// export const getUserGroups = async () => {
// 	<MsalContext.Consumer>
// 		{(context) => {
// 			if (context && context.instance) {
// 				const msalInstance = context.instance;

// 				try {
// 					const response = axios.get(
// 						"https://graph.microsoft.com/v1.0/me/memberOf",
// 						{
// 							headers: {
// 								Authorization: `Bearer ${
// 									msalInstance.getMsalInstance().getAccount()
// 										.idToken
// 								}`,
// 							},
// 						}
// 					);
// 					return response.data.value;
// 				} catch (error) {
// 					console.error("Error fetching user groups:", error);
// 					return [];
// 				}
// 			}
// 		}}
// 	</MsalContext.Consumer>;
// };

export const getUserGroups = async () => {
  return (
    <MsalContext.Consumer>
      {(context) => {
        if (context && context.instance) {
          const msalInstance = context.instance;

          const fetchUserGroups = async () => {
            try {
              const response = await axios.get(
                "https://graph.microsoft.com/v1.0/me/memberOf",
                {
                  headers: {
                    Authorization: `Bearer ${
                      msalInstance.getMsalInstance().getAccount().idToken
                    }`,
                  },
                }
              );
              return response.data.value;
            } catch (error) {
              console.error("Error fetching user groups:", error);
              return [];
            }
          };

          return fetchUserGroups();
        }
      }}
    </MsalContext.Consumer>
  );
};
